.contact {
    margin-top: 10rem;
    height: 30rem;
    width: 100vw;

    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
}

.contact-section-title {
    padding: 5rem 5rem;
    color: var(--text);
    font-family: "Source Code Pro", sans-serif;
    background-color: var(--background-lighter);
}

.contact-section-title h1 {
    font-size: 6.4rem;
}

.contact-section-title h3 {
    font-size: 1.8rem;
    margin-left: 2rem;
}

.contact-section {
    background-color: var(--accent);
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    padding-left: 5rem;
}

.contact-container {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.contact-text a {
    font-size: 1.8rem;
    font-family: "Source Code Pro", sans-serif;
    color: var(--text);
    text-decoration: none;

}

.contact-text {
    transition: transform 300ms ease;
}

.contact-text:hover {
    transform: scale(110%);
}

.contact-svg-container {
    height: 3rem;
    width: 3rem;
}

.contact-svg-container svg {
    height: 100%;
    width: 100%;

}

.contact-svg-container svg path {
    stroke: var(--text);
}