.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  position: relative;
  max-width: 1400px;
  width: 1100px;

  background-color: var(--background);
  border: 3px solid var(--text);
  padding: 40px;
  border-radius: 10px;
  z-index: 999;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.close-btn:hover {
  color: #666;
}

.modal-project-title {
  color: var(--text);
  font-size: 56px;
  margin-bottom: 10px;
}

.modal-project-image-container {
  width: 600px;
  max-height: 500px;
  border: 2px solid var(--text);
  border-radius: 5px;
  overflow: hidden;
  margin-right: 40px;
}

.modal-project-image {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: auto;
}

.modal-tools {
  margin-bottom: 30px;
}

.modal-tools-and-links {
  display: flex;
  justify-content: space-between;
}

.modal-links {
  display: flex;
  gap: 30px;
}

.modal-links .project-link {
  width: 50px;
  height: 50px;
}

.modal-tools .tool {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.modal-tools .tool .tool-label {
  border: 1px solid var(--text);
  border-radius: 5px;
}

.modal-project-description {
  color: var(--text);
  font-size: 34px;
  flex: 1;
  font-size: 24px;
}

.modal-details {
  display: flex;
}
