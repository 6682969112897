.home {
  height: 100vh;
  width: 100vw;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -6;
  background: linear-gradient(
    to bottom,
    var(--primary),
    var(--background) 70%,
    var(--background)
  );
}

.home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(10, 10, 10, 0.7),
    rgba(10, 10, 10, 0.1) 70%,
    rgba(10, 10, 10, 0.9)
  );
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 12.4rem;
  font-family: "Source Code Pro", sans-serif;
  color: var(--text);
  text-transform: uppercase;
}

.short-desc {
  font-size: 2.8rem;
  font-family: "Source Code Pro", sans-serif;
  color: var(--text);
  text-transform: uppercase;
}

.see-more {
  background-color: white;
  display: none;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}

/* .logo, .nav, .see-more, .title-section{
    display: none !important;
}  */
