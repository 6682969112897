@import url("https://fonts.googleapis.com/css2?family=Nabla&family=Source+Code+Pro:wght@200;300;500&display=swap");

:root {
  --background: #070904;
  --background-lighter: #1e1f1d;
  --text: #eaf0de;
  --text-dark: #b9bdb1;
  --text-darker: #91948b;
  --primary: #62cfe2;
  --primary-dark: #387883;
  --accent: #923fe6;

  --highlight-orange: #ff6c2c;
  /*--highlight-green: #38c93d;*/
  --highlight-pink: #de048c;
  --highlight-blue: #2e14f5;

  --github: #6cc644;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

/* GENERAL DOCUMENT STYLES */

body {
  background-color: var(--background);
  overflow-x: hidden;
}

#root {
  position: relative;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-lighter);
  border-radius: 6px;
}

.container {
  max-width: 1200px;
}
