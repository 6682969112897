/* NAVBAR STYLES */

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 7.6rem;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 99;
}



.nav-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: "Source Code Pro", sans-serif;
    padding: 2rem 2rem;
    cursor: pointer;
}


.not-hovered .nav-item-index, .not-hovered .nav-item-name{
    color: var(--text-darker);
}

.nav-item-index {
    font-size: 1.2rem;
    color: var(--text-dark);
    transition: color 1s ease;
}

.nav-item-name {
    font-size: 2.4rem;
    margin-top: -.5rem;
    color: var(--text);
    transition: color 300ms ease;
}

.sticky-nav {
    position: fixed;
    backdrop-filter: blur(10px);
    height: 5.6rem;
    animation: slide 300ms ease forwards;
    animation-iteration-count: 1;
}

.sticky-nav .nav-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: "Source Code Pro", sans-serif;
    padding: 2rem 2rem;
    cursor: pointer;
}


.sticky-nav.not-hovered .nav-item-index, .sticky-nav .not-hovered .nav-item-name{
    color: var(--primary-dark);
}

.sticky-nav .nav-item-index {
    font-size: .8rem;
    color: var(--primary);
    transition: color 1s ease;
}

.sticky-nav .nav-item-name {
    font-size: 1.8rem;
    margin-top: -.5rem;
    color: var(--primary);
    transition: color 300ms ease;
}

@keyframes slide {
    from {
        transform: translateY(-100%) translateX(-50%);
    }
    to {
        transform: translateY(0) translateX(-50%)
    }
}