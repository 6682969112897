.expertise {
    min-height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 12rem 0 0 0;
}

.expertise .section-title {
    color: var(--text);
    font-size: 8.6rem;
    font-family: "Source Code Pro", sans-serif;
    text-transform: uppercase;
    margin-top: 10rem;
}

.skills {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.skill {
    color: var(--text);
    font-family: "Source Code Pro", sans-serif;

    border: solid 2px var(--text);
    border-radius: 2rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3rem 2rem;
    transition: transform 300ms ease-out;
}

.skill:hover {
    transform: scale(103%);
}

.skill:nth-child(even) .skill-title-section {
    grid-column: 2;
    grid-row: 1;
}

.skill:nth-child(even) .skill-desc-section {
    grid-column: 1;
    grid-row: 1;
}

.skill-desc-section {
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.skill-title-section {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 2rem;
}

.skill-name {
    font-size: 3.6rem;
    position: relative;
}

.skill-image {
    height: 5.8rem;
    width: 5.8rem;
}

.skill-desc {
    font-size: 1.8rem;
}

/* HIGHLIGHTS */

.skill .skill-title-section .skill-name::before {
    content: '';
    height: 30%;
    width: 100%;
    position: absolute;
    top: 60%;
    left: 0;
    z-index: -2;
}

.skill:nth-child(1) .skill-title-section .skill-name::before {
    background-color: var(--highlight-blue);
}
.skill:nth-child(2) .skill-title-section .skill-name::before {
    background-color: var(--highlight-pink);
}
.skill:nth-child(3) .skill-title-section .skill-name::before {
    background-color: var(--highlight-orange);
}
