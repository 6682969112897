.work {
  min-height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  padding: 12rem 0 4rem 0;
}

.section-title {
  color: var(--text);
  font-size: 8.6rem;
  font-family: "Source Code Pro", sans-serif;
  text-transform: uppercase;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.projects {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  gap: 3rem;
}

.project {
  background-color: var(--background-lighter);
  min-height: 26rem;
  max-height: 52rem;
  border-radius: 2rem;
  overflow: hidden;
}

.project-image-container {
  width: 100%;
  height: 70%;
  overflow: hidden;
  cursor: pointer;
}

.project-image-container .project-image {
  width: 100%;
  vertical-align: top;
  transition: transform 300ms ease-out;
  object-fit: cover;
}

.project-image:hover {
  transform: scale(105%);
}

.project-title-section {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  font-family: "Source Code Pro", sans-serif;
  padding: 3rem 3rem;
}

.tools-and-links {
  display: flex;
  justify-content: space-between;
}

.project-title {
  display: inline;
  color: var(--text);
  font-size: 2.4rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  border-bottom: 4px solid transparent;
  transition: all 300ms ease;
  cursor: pointer;
}

.project-title:hover {
  border-bottom: 4px solid var(--text);
}

.links-container {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  gap: 4rem;
}

.project-link {
  height: 3.2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.project-link svg {
  width: 100%;
  height: 100%;
}

.project-link span {
  color: var(--text);
  font-size: 1.8rem;
}

.tools {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.tool {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 1rem;
  /*border: solid 1px var(--background);*/
  padding: 0.5rem;
}

.tool img {
  width: 100%;
  height: 100%;
}

.project {
  grid-row: span 3;
}

.small {
  grid-column: span 2;
}

.medium {
  grid-column: span 3;
}

.large {
  grid-column: span 4;
}

.hidden {
  display: none;
}

.link-icon path {
  stroke: var(--text);
  transition: stroke 300ms ease-out, transform 300ms ease-out;
}

.github-hover path {
  stroke: var(--github);
  transform: scale(110%);
}

.link-hover path {
  stroke: var(--primary);
  transform: scale(110%);
}

.tool-label {
  position: absolute;
  bottom: -10px;
  left: 50%;
  background-color: var(--background);
  color: var(--text);
  padding: 5px 10px;
  border: 5px;
  opacity: 0;
  transition: opacity 300ms ease;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}

.tool:hover .tool-label {
  opacity: 1;
}
